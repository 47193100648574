body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}

code {
  font-family: Rotobo, source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.page {
  height: 100vh;
}
.page-with-nav {
  padding-top: 3.25rem;
  min-height: 100vh;
}

.filterImg {
  -webkit-filter: invert(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(30%) hue-rotate(50deg);
}

.link {
  font-weight: bold;
  color: black;
  text-decoration:none
}
.link a:hover {
  color: black !important;
}
.link a:link {
  color: black;
}

/* visited link */
.link a:visited {
  color: black;
}

/* selected link */
.link a:active {
  color: black;
}
